var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
exports = Yallist;
Yallist.Node = Node;
Yallist.create = Yallist;

function Yallist(list) {
  var self = this || _global;

  if (!(self instanceof Yallist)) {
    self = new Yallist();
  }

  self.tail = null;
  self.head = null;
  self.length = 0;

  if (list && typeof list.forEach === "function") {
    list.forEach(function (item) {
      self.push(item);
    });
  } else if (arguments.length > 0) {
    for (var i = 0, l = arguments.length; i < l; i++) {
      self.push(arguments[i]);
    }
  }

  return self;
}

Yallist.prototype.removeNode = function (node) {
  if (node.list !== (this || _global)) {
    throw new Error("removing node which does not belong to this list");
  }

  var next = node.next;
  var prev = node.prev;

  if (next) {
    next.prev = prev;
  }

  if (prev) {
    prev.next = next;
  }

  if (node === (this || _global).head) {
    (this || _global).head = next;
  }

  if (node === (this || _global).tail) {
    (this || _global).tail = prev;
  }

  node.list.length--;
  node.next = null;
  node.prev = null;
  node.list = null;
};

Yallist.prototype.unshiftNode = function (node) {
  if (node === (this || _global).head) {
    return;
  }

  if (node.list) {
    node.list.removeNode(node);
  }

  var head = (this || _global).head;
  node.list = this || _global;
  node.next = head;

  if (head) {
    head.prev = node;
  }

  (this || _global).head = node;

  if (!(this || _global).tail) {
    (this || _global).tail = node;
  }

  (this || _global).length++;
};

Yallist.prototype.pushNode = function (node) {
  if (node === (this || _global).tail) {
    return;
  }

  if (node.list) {
    node.list.removeNode(node);
  }

  var tail = (this || _global).tail;
  node.list = this || _global;
  node.prev = tail;

  if (tail) {
    tail.next = node;
  }

  (this || _global).tail = node;

  if (!(this || _global).head) {
    (this || _global).head = node;
  }

  (this || _global).length++;
};

Yallist.prototype.push = function () {
  for (var i = 0, l = arguments.length; i < l; i++) {
    push(this || _global, arguments[i]);
  }

  return (this || _global).length;
};

Yallist.prototype.unshift = function () {
  for (var i = 0, l = arguments.length; i < l; i++) {
    unshift(this || _global, arguments[i]);
  }

  return (this || _global).length;
};

Yallist.prototype.pop = function () {
  if (!(this || _global).tail) {
    return undefined;
  }

  var res = (this || _global).tail.value;
  (this || _global).tail = (this || _global).tail.prev;

  if ((this || _global).tail) {
    (this || _global).tail.next = null;
  } else {
    (this || _global).head = null;
  }

  (this || _global).length--;
  return res;
};

Yallist.prototype.shift = function () {
  if (!(this || _global).head) {
    return undefined;
  }

  var res = (this || _global).head.value;
  (this || _global).head = (this || _global).head.next;

  if ((this || _global).head) {
    (this || _global).head.prev = null;
  } else {
    (this || _global).tail = null;
  }

  (this || _global).length--;
  return res;
};

Yallist.prototype.forEach = function (fn, thisp) {
  thisp = thisp || this || _global;

  for (var walker = (this || _global).head, i = 0; walker !== null; i++) {
    fn.call(thisp, walker.value, i, this || _global);
    walker = walker.next;
  }
};

Yallist.prototype.forEachReverse = function (fn, thisp) {
  thisp = thisp || this || _global;

  for (var walker = (this || _global).tail, i = (this || _global).length - 1; walker !== null; i--) {
    fn.call(thisp, walker.value, i, this || _global);
    walker = walker.prev;
  }
};

Yallist.prototype.get = function (n) {
  for (var i = 0, walker = (this || _global).head; walker !== null && i < n; i++) {
    // abort out of the list early if we hit a cycle
    walker = walker.next;
  }

  if (i === n && walker !== null) {
    return walker.value;
  }
};

Yallist.prototype.getReverse = function (n) {
  for (var i = 0, walker = (this || _global).tail; walker !== null && i < n; i++) {
    // abort out of the list early if we hit a cycle
    walker = walker.prev;
  }

  if (i === n && walker !== null) {
    return walker.value;
  }
};

Yallist.prototype.map = function (fn, thisp) {
  thisp = thisp || this || _global;
  var res = new Yallist();

  for (var walker = (this || _global).head; walker !== null;) {
    res.push(fn.call(thisp, walker.value, this || _global));
    walker = walker.next;
  }

  return res;
};

Yallist.prototype.mapReverse = function (fn, thisp) {
  thisp = thisp || this || _global;
  var res = new Yallist();

  for (var walker = (this || _global).tail; walker !== null;) {
    res.push(fn.call(thisp, walker.value, this || _global));
    walker = walker.prev;
  }

  return res;
};

Yallist.prototype.reduce = function (fn, initial) {
  var acc;
  var walker = (this || _global).head;

  if (arguments.length > 1) {
    acc = initial;
  } else if ((this || _global).head) {
    walker = (this || _global).head.next;
    acc = (this || _global).head.value;
  } else {
    throw new TypeError("Reduce of empty list with no initial value");
  }

  for (var i = 0; walker !== null; i++) {
    acc = fn(acc, walker.value, i);
    walker = walker.next;
  }

  return acc;
};

Yallist.prototype.reduceReverse = function (fn, initial) {
  var acc;
  var walker = (this || _global).tail;

  if (arguments.length > 1) {
    acc = initial;
  } else if ((this || _global).tail) {
    walker = (this || _global).tail.prev;
    acc = (this || _global).tail.value;
  } else {
    throw new TypeError("Reduce of empty list with no initial value");
  }

  for (var i = (this || _global).length - 1; walker !== null; i--) {
    acc = fn(acc, walker.value, i);
    walker = walker.prev;
  }

  return acc;
};

Yallist.prototype.toArray = function () {
  var arr = new Array((this || _global).length);

  for (var i = 0, walker = (this || _global).head; walker !== null; i++) {
    arr[i] = walker.value;
    walker = walker.next;
  }

  return arr;
};

Yallist.prototype.toArrayReverse = function () {
  var arr = new Array((this || _global).length);

  for (var i = 0, walker = (this || _global).tail; walker !== null; i++) {
    arr[i] = walker.value;
    walker = walker.prev;
  }

  return arr;
};

Yallist.prototype.slice = function (from, to) {
  to = to || (this || _global).length;

  if (to < 0) {
    to += (this || _global).length;
  }

  from = from || 0;

  if (from < 0) {
    from += (this || _global).length;
  }

  var ret = new Yallist();

  if (to < from || to < 0) {
    return ret;
  }

  if (from < 0) {
    from = 0;
  }

  if (to > (this || _global).length) {
    to = (this || _global).length;
  }

  for (var i = 0, walker = (this || _global).head; walker !== null && i < from; i++) {
    walker = walker.next;
  }

  for (; walker !== null && i < to; i++, walker = walker.next) {
    ret.push(walker.value);
  }

  return ret;
};

Yallist.prototype.sliceReverse = function (from, to) {
  to = to || (this || _global).length;

  if (to < 0) {
    to += (this || _global).length;
  }

  from = from || 0;

  if (from < 0) {
    from += (this || _global).length;
  }

  var ret = new Yallist();

  if (to < from || to < 0) {
    return ret;
  }

  if (from < 0) {
    from = 0;
  }

  if (to > (this || _global).length) {
    to = (this || _global).length;
  }

  for (var i = (this || _global).length, walker = (this || _global).tail; walker !== null && i > to; i--) {
    walker = walker.prev;
  }

  for (; walker !== null && i > from; i--, walker = walker.prev) {
    ret.push(walker.value);
  }

  return ret;
};

Yallist.prototype.reverse = function () {
  var head = (this || _global).head;
  var tail = (this || _global).tail;

  for (var walker = head; walker !== null; walker = walker.prev) {
    var p = walker.prev;
    walker.prev = walker.next;
    walker.next = p;
  }

  (this || _global).head = tail;
  (this || _global).tail = head;
  return this || _global;
};

function push(self, item) {
  self.tail = new Node(item, self.tail, null, self);

  if (!self.head) {
    self.head = self.tail;
  }

  self.length++;
}

function unshift(self, item) {
  self.head = new Node(item, null, self.head, self);

  if (!self.tail) {
    self.tail = self.head;
  }

  self.length++;
}

function Node(value, prev, next, list) {
  if (!((this || _global) instanceof Node)) {
    return new Node(value, prev, next, list);
  }

  (this || _global).list = list;
  (this || _global).value = value;

  if (prev) {
    prev.next = this || _global;
    (this || _global).prev = prev;
  } else {
    (this || _global).prev = null;
  }

  if (next) {
    next.prev = this || _global;
    (this || _global).next = next;
  } else {
    (this || _global).next = null;
  }
}

export default exports;